import React from "react";
import "../styles/JoinUs.css";

class joinUs extends React.Component {
    render() {
        return (
            <div className="join">
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfYzuG6JbmkvjiSbyyEbz95DbA9sCv7qYKCsdz2jsKj6YpfLQ/viewform?embedded=true" width="100%" height="1300px" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </div>
        );
    }
}

export default joinUs;