import React from "react";
import "../styles/OurTeam.css";
import logo from "../images/IMG_7464.PNG";


class OurTeam extends React.Component {
    render() {
        return (
            <div className="team">
                <div className="title">
                    <h1>Our Team</h1>
                </div>
                <div className="all-execs">

                    <div className="exec">
                        <img src={logo} alt="Marina Zheng"></img>
                        <h2>Marina Zheng</h2>
                        <p>President</p>
                    </div>
                    
                    <div className="exec">
                        <img src={logo} alt="Tiantian Li"></img>
                        <h2>Tiantian Li</h2>
                        <p>Secretary / VP Admin</p>
                    </div>

                    <div className="exec">
                        <img src={logo} alt="Janice Ng"></img>
                        <h2>Janice Ng</h2>
                        <p>VP Finance</p>
                    </div>

                    <div className="exec">
                        <img src={logo} alt="Bonnie Peng"></img>
                        <h2>Bonnie Peng</h2>
                        <p>Web Developer</p>
                    </div>

                    <div className="exec">
                        <img src={logo} alt="Exec Profile"></img>
                        <h2>Name</h2>
                        <p>Role</p>
                    </div>

                    <div className="exec">
                        <img src={logo} alt="Exec Profile"></img>
                        <h2>Name</h2>
                        <p>Role</p>
                    </div>
                    
                    <div className="exec">
                        <img src={logo} alt="Exec Profile"></img>
                        <h2>Name</h2>
                        <p>Role</p>
                    </div>

                    <div className="exec">
                        <img src={logo} alt="Exec Profile"></img>
                        <h2>Name</h2>
                        <p>Role</p>
                    </div>

                    <div className="exec">
                        <img src={logo} alt="Exec Profile"></img>
                        <h2>Name</h2>
                        <p>Role</p>
                    </div>

                    <div className="exec">
                        <img src={logo} alt="Exec Profile"></img>
                        <h2>Name</h2>
                        <p>Role</p>
                    </div>

                    <div className="exec">
                        <img src={logo} alt="Exec Profile"></img>
                        <h2>Name</h2>
                        <p>Role</p>
                    </div>

                    <div className="exec">
                        <img src={logo} alt="Exec Profile"></img>
                        <h2>Name</h2>
                        <p>Role</p>
                    </div>

                    <div className="exec">
                        <img src={logo} alt="Exec Profile"></img>
                        <h2>Name</h2>
                        <p>Role</p>
                    </div>

                    <div className="exec">
                        <img src={logo} alt="Exec Profile"></img>
                        <h2>Name</h2>
                        <p>Role</p>
                    </div>

                    <div className="exec">
                        <img src={logo} alt="Exec Profile"></img>
                        <h2>Name</h2>
                        <p>Role</p>
                    </div>

                    <div className="exec">
                        <img src={logo} alt="Exec Profile"></img>
                        <h2>Name</h2>
                        <p>Role</p>
                    </div>

                    <div className="exec">
                        <img src={logo} alt="Exec Profile"></img>
                        <h2>Name</h2>
                        <p>Role</p>
                    </div>

                    <div className="exec">
                        <img src={logo} alt="Exec Profile"></img>
                        <h2>Name</h2>
                        <p>Role</p>
                    </div>

                    <div className="exec">
                        <img src={logo} alt="Exec Profile"></img>
                        <h2>Name</h2>
                        <p>Role</p>
                    </div>

                    <div className="exec">
                        <img src={logo} alt="Exec Profile"></img>
                        <h2>Name</h2>
                        <p>Role</p>
                    </div>

                    <div className="exec">
                        <img src={logo} alt="Exec Profile"></img>
                        <h2>Name</h2>
                        <p>Role</p>
                    </div>

                    <div className="exec">
                        <img src={logo} alt="Exec Profile"></img>
                        <h2>Name</h2>
                        <p>Role</p>
                    </div>

                    <div className="exec">
                        <img src={logo} alt="Exec Profile"></img>
                        <h2>Name</h2>
                        <p>Role</p>
                    </div>

                    <div className="exec">
                        <img src={logo} alt="Exec Profile"></img>
                        <h2>Name</h2>
                        <p>Role</p>
                    </div>

                </div>
            </div>
        );
    }
}

export default OurTeam;